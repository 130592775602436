// src/App.js
import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/NavBar/NavBar';
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import GalleryPage from './pages/GalleryPage/GalleryPage';
import PartnerShips from "./pages/Partnerships/PartnerShips";
const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, [pathname]);

    return null;
};

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <ScrollToTop />
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/gallery" element={<GalleryPage />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/partnerships" element={<PartnerShips />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;
