// src/components/PropertyDetails/PropertyDetails.js
import React from 'react';
import { Container, Grid } from '@mui/material';
import PropertyDescription from '../PropertyDescription/PropertyDescription';
import AmenitiesText from '../AmenitiesText/AmenitiesText';
import './_PropertyDetails.scss';
import Amenities from "../Amenities/Amenities";

const PropertyDetails = () => {
    return (
        <Container className="property-details" maxWidth={false} disableGutters>
            <div className="property-description-container">
                <PropertyDescription />
            </div>
        </Container>
    );
};

export default PropertyDetails;
