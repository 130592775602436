// src/components/PropertyDescription/PropertyDescription.js
import React from 'react';
import {Box, Button, Container, Grid, Typography} from '@mui/material';
import img4 from '../../images/gallery/exterior/Clairmount_Ext_4.jpg';
import './_PropertyDescription.scss';
const PropertyDescription = () => {
    return (
        <Container className="property-description">
            <Grid container spacing={8} >
                <Grid item xs={12} md={6}>
                    <Box className="square-box">
                        <img
                            src={img4}
                            className="room-image"

                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>  {/* Add sx to left-align */}
                    <Typography variant="h6" className="property-title">See the possibilities of<br/>2080 Clairmount Street Detroit, MI
                    </Typography>
                    <Typography variant="body1" className="property-description-text">
                        Attention Real Estate Investors and Developers! Incredible Rehab Opportunity in Historic Virginia Park, Detroit. Built in 1928, this expansive corner property offers an opportunity for renovation, with 5,628 square feet of space to transform it into something incredible. Fully gutted and primed for renovation, this property is ready to become a piece of Detroit's rejuvenation in one of the most sought- after neighborhoods. Zoned R2, this property offers versatility for residential single-family, two-family, multi-family, and commercial usage. A total lot size of 8,276 square feet allowing for a large space for potential development or expansion.Prime corner location maximizes both visibility and accessibility. With proximity to Henry Ford Hospital, the iconic Motown Museum and The Congregation Coffee house, this property is centrally located to all that is celebrated in Detroit. Take advantage of the high demand for housing and commercial spaces in Detroit- with flexible zoning and prime location this property offers investment opportunities from residential to commercial ventures. Please note- The seller has plans for an 8 unit including the basement and second parcel for parking. Lot next to property is included in the sale (2072 Clairmount).
                    </Typography>
                </Grid>
            </Grid>

        </Container>
    );
};

export default PropertyDescription;
