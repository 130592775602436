import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, Box, Typography } from '@mui/material';
import axios from 'axios';
import './_ContactModal.scss'; // Ensure the same styles are used

const ContactModal = ({ open, handleClose, agent }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });
    const [statusMessage, setStatusMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formBody = new FormData();
        formBody.append('name', formData.name);
        formBody.append('email', formData.email);
        formBody.append('subject', formData.subject);
        formBody.append('message', formData.message);
        formBody.append('agent', agent.name);

        try {
            const response = await axios.post('/server/sendEmail.php', formBody);
            setStatusMessage(response.data.message || 'Email sent successfully!');
        } catch (error) {
            setStatusMessage('Failed to send email. Please try again.');
            console.error(error);
        }

        // Reset the form and close the modal
        setFormData({ name: '', email: '', subject: '', message: '' });
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="contact-modal-title" maxWidth="sm" fullWidth>
            <DialogContent>
                <form onSubmit={handleSubmit} className="contact-form" noValidate autoComplete="off">
                    <Typography variant="h5" className="form-title">Contact {agent.name}</Typography>

                    <TextField
                        label="Your Name"
                        name="name"
                        fullWidth
                        required
                        margin="normal"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="form-input"
                    />
                    <TextField
                        label="Your Email"
                        name="email"
                        type="email"
                        fullWidth
                        required
                        margin="normal"
                        value={formData.email}
                        onChange={handleInputChange}
                        className="form-input"
                    />
                    <TextField
                        label="Subject"
                        name="subject"
                        fullWidth
                        margin="normal"
                        value={formData.subject}
                        onChange={handleInputChange}
                        className="form-input"
                    />
                    <TextField
                        label="Message"
                        name="message"
                        fullWidth
                        multiline
                        rows={4}
                        margin="normal"
                        value={formData.message}
                        onChange={handleInputChange}
                        className="form-input"
                        required
                    />

                    {/* Centered Submit Button */}
                    <Box mt={2} display="flex" justifyContent="center">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="submit-button"
                            style={{ width: '200px', height: '65px' }}
                        >
                            Send Message
                        </Button>
                    </Box>
                    {statusMessage && <Typography>{statusMessage}</Typography>}
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ContactModal;
