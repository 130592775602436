import React, { useState } from 'react';
import { Container, Grid, Typography, Tabs, Tab, Button, Box, Dialog, DialogContent } from '@mui/material';
import './_Gallery.scss';
import img1 from '../../images/gallery/interior/Clairmount_INT_201.jpg';
import img2 from '../../images/gallery/interior/Clairmount_INT_203.jpg';
import img3 from '../../images/gallery/interior/Clairmount_INT_301.jpg';
import img4 from '../../images/gallery/interior/Clairmount_INT_303.jpg';

const images = [img1, img2, img3, img4];
const roomDetails = [
    {
        title: 'Room 1',
        details: [
            { label: 'About', value: 'Fully gutted and primed for renovation, this property is ready to become a piece of Detroit\'s rejuvenation in one of the most sought- after neighborhoods.' },
        ],
    },
    {
        title: 'Room 2',
        details: [
            { label: 'About', value: 'Fully gutted and primed for renovation, this property is ready to become a piece of Detroit\'s rejuvenation in one of the most sought- after neighborhoods.' },
        ],
    },
    {
        title: 'Room 3',
        details: [
            { label: 'About', value: 'Fully gutted and primed for renovation, this property is ready to become a piece of Detroit\'s rejuvenation in one of the most sought- after neighborhoods.' },
        ],
    },
    {
        title: 'Room 4',
        details: [
            { label: 'About', value: 'Fully gutted and primed for renovation, this property is ready to become a piece of Detroit\'s rejuvenation in one of the most sought- after neighborhoods.' },
        ],
    },
];

const GalleryInt = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [open, setOpen] = useState(false);
    const [selectedImg, setSelectedImg] = useState('');

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleImageClick = (img) => {
        setSelectedImg(img);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={'contain-bg'}>
        <Container className="gallery-section" maxWidth="md" sx={{ margin: 'auto', textAlign: 'center' }}> {/* Centered container */}
            <Typography variant="h4" gutterBottom className="property-title" textAlign="center" sx={{ marginBottom: '70px' }}>
                Possibility of Rooms
            </Typography>

            {/* Centering the Box below the title */}
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" width="100%">
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="Room Tabs"
                    sx={{
                        '& .MuiTab-root': {
                            backgroundColor: 'white',
                            border: '1px solid grey',
                            color: 'black',
                            margin: '0 10px',
                            textTransform: 'none',
                        },
                        '& .Mui-selected': {
                            backgroundColor: '#1a1b1d',  // Set active button color
                            color: 'white !important',
                            boxShadow: 'none',  // Remove shadow
                        },
                        '& .MuiTabs-flexContainer': {
                            justifyContent: 'flex-start',
                        },
                    }}
                >
                    {roomDetails.map((room, index) => (
                        <Tab label={room.title} key={index} />
                    ))}
                </Tabs>


                <Box mt={4} width="100%" className={'gallery-int-contain'}>
                    {roomDetails.map((room, index) => (
                        selectedTab === index && (
                            <Grid container spacing={4} key={index} justifyContent="center" alignItems="center">
                                <Grid item xs={12} md={6}>
                                    <Box className="square-box">
                                        <img
                                            src={images[index]}
                                            alt={`Room ${index + 1}`}
                                            className="room-image"
                                            onClick={() => handleImageClick(images[index])}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ textAlign: 'left' }}>  {/* Add sx to left-align */}
                                    <Typography variant="h5" gutterBottom>
                                        {room.title} Details
                                    </Typography>
                                    <ul>
                                        {room.details.map((detail, idx) => (
                                            <li key={idx}>
                                                <strong>{detail.label}:</strong> {detail.value}
                                            </li>
                                        ))}
                                    </ul>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ backgroundColor: '#1a1b1d', marginTop: '20px' }}
                                        href="/gallery"
                                    >
                                        See More
                                    </Button>
                                </Grid>
                            </Grid>
                        )
                    ))}
                </Box>
            </Box>

            {/* Modal for Full-size Image */}
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogContent
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '0',
                    }}
                >
                    <img
                        src={selectedImg}
                        alt="Selected"
                        style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'contain', // Ensures the image scales properly
                        }}
                    />
                </DialogContent>
            </Dialog>

        </Container>
        </div>
    );
};

export default GalleryInt;
