import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    Button,
    Box,
    Container,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    useMediaQuery
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Menu as MenuIcon } from '@mui/icons-material';
import './_NavBar.scss';
import img1 from '../../images/logo2.png';
import { useTheme } from '@mui/material/styles';

const NavBar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // For screen sizes below medium (mobile)

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const navLinks = (
        <>
            <Button component={Link} to="/" color="inherit" className="nav-link">Home</Button>
            <Button component={Link} to="/gallery" color="inherit" className="nav-link">Gallery</Button>
            <Button component={Link} to="/contact" color="inherit" className="nav-link">Contact</Button>
            <Button component={Link} to="/partnerships" color="inherit" className="nav-link">Investment Partnerships</Button>
        </>
    );

    return (
        <AppBar position="sticky" className="navbar">
            <Toolbar className="toolbar">
                <Container maxWidth="lg" className="nav-container">
                    {/* Left-aligned logo */}
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                        <Link to="/">
                            <img src={img1} alt="Logo" style={{ height: '100px', width: 'auto' }} />
                        </Link>
                    </Box>

                    {isMobile ? (
                        // Show hamburger menu on mobile
                        <>
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="menu"
                                onClick={handleDrawerToggle}
                                sx={{ ml: 'auto' }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Drawer
                                anchor="right"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                            >
                                <List>
                                    <ListItem button onClick={handleDrawerToggle}>
                                        <ListItemText>
                                            <Link to="/">Home</Link>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem button onClick={handleDrawerToggle}>
                                        <ListItemText>
                                            <Link to="/gallery">Gallery</Link>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem button onClick={handleDrawerToggle}>
                                        <ListItemText>
                                            <Link to="/contact">Contact</Link>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem button onClick={handleDrawerToggle}>
                                        <ListItemText>
                                            <Link to="/partnerships">Investment Partnerships</Link>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Drawer>
                        </>
                    ) : (
                        // Show full nav links on larger screens
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                            {navLinks}
                        </Box>
                    )}
                </Container>
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
