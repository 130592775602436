import React from 'react';
import { Container, Typography, List, ListItem, ListItemText } from '@mui/material';
import './_AmenitySection.scss';

const AmenitySection = ({ title, items }) => {
    return (
        <Container className="amenity-section">
            <Typography variant="h5" className="amenity-title">{title}</Typography>
            <List>
                {items.map((item, index) => (
                    <ListItem key={index} className="amenity-list-item">
                        <ListItemText primary={`${item.label}: ${item.value}`} />
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};

export default AmenitySection;
