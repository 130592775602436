// src/components/MapSection/MapSection.js
import React from 'react';
import { Container, Typography } from '@mui/material';
import './_MapSection.scss';
import map from '../../images/map.png';

const MapSection = () => {
    return (
        <Container className="map-section" maxWidth={false} disableGutters> {/* Ensures the container is full-width */}
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1423.5287122646887!2d-83.10405915181555!3d42.37540352273536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824cd0d02429d87%3A0x595cfa9c1f637225!2s2080%20Clairmount%20Ave%2C%20Detroit%2C%20MI%2048206!5e0!3m2!1sen!2sus!4v1728743666037!5m2!1sen!2sus"
                width="100%" height="550" style={{border: 0}} allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
        </Container>
    );
};

export default MapSection;
