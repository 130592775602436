// src/pages/Home/Home.js
import React from 'react';
import Navbar from '../../components/NavBar/NavBar';
import Hero from '../../components/Hero/Hero';
import PropertyDescription from '../../components/PropertyDescription/PropertyDescription';
import Amenities from '../../components/Amenities/Amenities';
import MapSection from '../../components/MapSection/MapSection';
import Gallery from '../../components/Gallery/Gallery';
import ContactSection from '../../components/ContactSection/ContactSection';
import Footer from '../../components/Footer/Footer';
import AmenitiesText from "../../components/AmenitiesText/AmenitiesText";
import PropertyDetails from "../../components/PropertyDetails/PropertyDetails";
import GalleryInt from "../../components/GalleryInt/GalleryInt";

const Home = () => {
    return (
        <>

            <Hero />
            <PropertyDetails />
            <AmenitiesText />
            <MapSection />
            <GalleryInt/>



            <Gallery />
            <ContactSection />
            <Footer />
        </>
    );
};

export default Home;
