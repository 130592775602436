// src/components/AmenitiesText/AmenitiesText.js
import React from 'react';
import AmenitySection from '../AmenitySection/AmenitySection';
import {Container, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import './_AmenitiesText.scss';
const AmenitiesText = () => {
    const amenitiesData = [
        {
            title: 'Interior',
            items: [
                { label: 'Square Foot', value: '5,682 sqft' },
                { label: 'Basement', value: 'Yes' },
                { label: 'Water Source\n', value: 'Public' },
            ],
        },
        {
            title: 'Exterior',
            items: [
                { label: 'Lot Size', value: '5000 sq ft' },
                { label: 'Property Type', value: 'Commercial' },
                { label: 'Lot Size Dimensions', value: '8276' },
                { label: 'Lot Size Area', value: '0.19 acres' },
            ],
        },
        {
            title: 'Building Details',
            items: [
                { label: 'Year Built', value: '1928' },
                { label: 'County', value: 'Wayne' },
                { label: 'Lot Size Area', value: '0.19 acres' },
                { label: 'Subdivision', value: 'Virginia Park Neighborhood' },
            ],
        },
        {
            title: 'Features',
            items: [
                { label: 'Heating', value: 'None' },
                { label: 'Construction', value: 'Brick' },
                { label: 'Basement', value: 'Finished' },

            ],
        },
    ];

    return (
        <div className={'contain-bg'}>
            <Container className="amenities-text">
                <Typography variant="h4" gutterBottom className="property-title" sx={{ marginBottom: '70px' }}>Features & Amenities</Typography>
                <Grid container spacing={4}>
                    {amenitiesData.map((section, index) => (
                        <Grid item xs={12} md={6} key={index}> {/* Display in 2 columns on medium+ screens */}
                            <AmenitySection title={section.title} items={section.items} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    );
};

export default AmenitiesText;
