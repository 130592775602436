// src/components/ContactSection/ContactSection.js
import React, {useState} from 'react';
import {Container, Grid, Button, Typography, IconButton} from '@mui/material';
import './_ContactSection.scss';
import img1 from '../../images/agents/rachel-blue.jpg';
import img2 from '../../images/agents/tracey.jpg';
import ContactModal from "../ContactModal/ContactModal";
import { Facebook, Twitter, Instagram } from '@mui/icons-material';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
const agents = [
    {
        name: 'Rachel Blue',
        description: 'I\'m an expert real estate agent with Elite Realty in Livonia, MI and the nearby area, providing home-buyers and sellers with professional, responsive and attentive real estate services. Want an agent who\'ll really listen to what you want in a home? Need an agent who knows how to effectively market your home so it sells? Give me a call! I\'m eager to help and would love to talk to you.',
        photo: img1,
        website: 'https://www.homes.com/real-estate-agents/rachel-blue/hhhb1d2/',
        facebook: 'https://www.facebook.com/people/RachelBlueRealty/100083184939518/',
    },
    {
        name: 'Tracey Petersen',
        description: 'I started as a property manager within under-resourced communities working alongside community activists in the pursuit of education and placing tenants within safe and affordable housing. 8 years later, I transitioned into a career in residential real estate and have continued to grow professionally ever since. ',
        photo: img2,
        website: 'https://www.tracypetersenrealtor.com',
        facebook: 'https://www.facebook.com/tracyeliterealty',
    },
];

const ContactSection = () => {
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = (agent) => {
        setSelectedAgent(agent);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedAgent(null);
        setIsModalOpen(false);
    };

    return (
        <Container className="contact-section" maxWidth={false} disableGutters>
            <Typography variant="h4" className="property-title" gutterBottom sx={{ marginBottom: '70px' }}>
                Meet Our Team
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                {agents.map((agent, index) => (
                    <Grid item xs={12} sm={12} md={6} lg={6} key={index} className="agent-card-container">
                        <div className="agent-card">
                            <img src={agent.photo} alt={agent.name} className="agent-photo" />
                            <div className="agent-info">
                                <Typography variant="h6">{agent.name}</Typography>
                                <Typography variant="body2">{agent.description}</Typography>

                                <div className="agent-actions">
                                    {/* Contact Button (Email Icon) */}
                                    <IconButton
                                        color="primary"
                                        onClick={() => handleOpenModal(agent)}
                                        aria-label={`Contact ${agent.name}`}
                                    >
                                        <EmailIcon />
                                    </IconButton>

                                    {/* Website and Social Media Icons - Now aligned next to the email icon */}
                                    <IconButton
                                        component="a"
                                        href={agent.website}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        color="primary"
                                    >
                                        <LanguageIcon />
                                    </IconButton>
                                    <IconButton
                                        component="a"
                                        href={agent.facebook}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        color="primary"
                                    >
                                        <Facebook />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>

            {selectedAgent && (
                <ContactModal open={isModalOpen} handleClose={handleCloseModal} agent={selectedAgent} />
            )}
        </Container>
    );
};

export default ContactSection;
