// src/components/GalleryInt/GalleryInt.js
import React, { useState } from 'react';
import { Container, Grid, Dialog, DialogContent } from '@mui/material';
import './_Gallery.scss';
import img1 from '../../images/gallery/exterior/Clairmount_Ext_1.jpg';
import img2 from '../../images/gallery/exterior/Clairmount_Ext_2.jpg';
import img3 from '../../images/gallery/exterior/Clairmount_Ext_3.jpg';
import img4 from '../../images/gallery/exterior/Clairmount_Ext_4.jpg';
const images = [
    img1,
    img2,
    img3,
    img4,
    // Add more image paths
];

const Gallery = () => {
    const [open, setOpen] = useState(false);
    const [selectedImg, setSelectedImg] = useState('');

    const handleOpen = (img) => {
        setSelectedImg(img);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedImg('');
    };

    return (

        <Container className="gallery-section" maxWidth={false} disableGutters>
            <Grid container spacing={2}>
                {images.map((img, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}> {/* Adjusted grid to make images larger and responsive */}
                        <img
                            src={img}
                            alt={`Gallery ${index + 1}`}
                            className="gallery-image"
                            onClick={() => handleOpen(img)}
                        />
                    </Grid>
                ))}
            </Grid>

            <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogContent>
                    <img src={selectedImg} alt="Selected" className="selected-image" />
                </DialogContent>
            </Dialog>
        </Container>

    );
};

export default Gallery;
