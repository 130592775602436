import React from 'react';
import Slider from 'react-slick';
import './_Hero.scss';
import img1 from '../../images/gallery/exterior/Clairmount_Ext_1.jpg';
import img2 from '../../images/gallery/exterior/Clairmount_Ext_2.jpg';
import img3 from '../../images/gallery/exterior/Clairmount_Ext_3.jpg';
import img4 from '../../images/gallery/exterior/Clairmount_Ext_4.jpg';
const heroImages = [
    img1,
    img2,
    img3,
    img4,
];

const Hero = () => {
    const settings = {
        dots: true, // Show navigation dots at the bottom
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <div className="slick-next">→</div>,
        prevArrow: <div className="slick-prev">←</div>,
        autoplay: true,
        autoplaySpeed: 10000, // Rotate every 3 seconds
    };

    return (
        <div className="hero-carousel">
            <Slider {...settings}>
                {heroImages.map((image, index) => (
                    <div key={index} className="hero-slide">
                        <img src={image} alt={`Hero Slide ${index + 1}`} className="hero-image" />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Hero;

