// src/pages/GalleryPage/GalleryPage.js
import React, { useState } from 'react';
import {Grid, Modal, IconButton, Dialog, DialogContent} from '@mui/material';
import './_GalleryPage.scss';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Footer from "../../components/Footer/Footer";
// Import all images from the images folder
function importAll(r) {
    return r.keys().map(r);
}

const images = importAll(require.context('../../images/gallery/all', false, /\.(png|jpe?g|svg)$/));


const GalleryPage = () => {
    const [open, setOpen] = useState(false);
    const [selectedImg, setSelectedImg] = useState('');
    const [selectedImgIndex, setSelectedImgIndex] = useState(0);
    const handleOpen = (img) => {
        setSelectedImg(img);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedImg('');
    };
    const handleNext = () => {
        setSelectedImgIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setSelectedImgIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <div className="page-container">
            <div className="content-wrap">
        <div className="gallery-page-container">
            <Grid container spacing={2}>
                {images.map((img, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <img
                            src={img}
                            alt={`Gallery ${index + 1}`}
                            className="gallery-image"
                            onClick={() => handleOpen(index)}
                        />
                    </Grid>
                ))}
            </Grid>

            {/* Modal to display selected image */}
            <Dialog open={open} onClose={handleClose} maxWidth="md" className="gallery-modal">
                <DialogContent>
                    <div className="modal-content">
                        {/* Left Arrow */}
                        <IconButton onClick={handlePrev} className="modal-nav left-arrow">
                            <ArrowBackIosIcon />
                        </IconButton>

                        {/* Image Display */}
                        <img src={images[selectedImgIndex]} alt={`Selected Image ${selectedImgIndex + 1}`} className="selected-image" />

                        {/* Right Arrow */}
                        <IconButton onClick={handleNext} className="modal-nav right-arrow">
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
                </div>
            <Footer/>
        </div>

    );
};

export default GalleryPage;
