// src/pages/PartnerShips/PartnerShips.js
import React, { useState } from 'react';
import {Container, TextField, Button, Typography, Grid, Box} from '@mui/material';
import Navbar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';
import './_PartnerShips.scss';

const PartnerShips = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [statusMessage, setStatusMessage] = useState(''); // For displaying status

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('/server/contactEmail.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(formData),  // Send form data as URL-encoded string
            });

            const result = await response.json();
            if (result.status === 'success') {
                setStatusMessage('Message sent successfully!');
            } else {
                setStatusMessage('Failed to send message. Please try again.');
            }
        } catch (error) {
            setStatusMessage('Error occurred while sending message.');
        }
    };

    return (
        <>
            <div className="page-container">
                <div className="content-wrap">
                    <Container className="contact-page">
                        <Grid container spacing={4} alignItems="center">
                            <Grid item xs={12} md={6}>
                                <Typography variant="h1" className="contact-title">
                                    Investments <br/><span className="highlight">and Partnerships</span>
                                </Typography>
                                <Typography variant="body1" className="contact-description">
                                    Reach out to us for any inquiries or assistance. Whether you're curious about our services or need personalized support, we're here to guide you every step of the way.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <form onSubmit={handleSubmit} className="contact-form">
                                    <Typography variant="h5" className="form-title">Send Us a Message</Typography>

                                    <TextField
                                        label="Name"
                                        name="name"
                                        fullWidth
                                        required
                                        margin="normal"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="form-input"
                                    />
                                    <TextField
                                        label="Email"
                                        name="email"
                                        type="email"
                                        fullWidth
                                        required
                                        margin="normal"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="form-input"
                                    />
                                    <TextField
                                        label="Message"
                                        name="message"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        required
                                        margin="normal"
                                        value={formData.message}
                                        onChange={handleChange}
                                        className="form-input"
                                    />
                                    <Box mt={2}>
                                        <Button type="submit" variant="contained" color="primary" fullWidth className="submit-button">
                                            Send Message
                                        </Button>
                                    </Box>
                                </form>
                                {/* Display status message */}
                                {statusMessage && <Typography variant="body2" color="error" mt={2}>{statusMessage}</Typography>}
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default PartnerShips;
