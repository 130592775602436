import React from 'react';
import { Container, Typography, Grid, Button, Box } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './_Footer.scss';
import img1 from '../../images/logo2.png';
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <footer className="footer">
            {/* Real Estate Journey Section */}
            <Box className="footer-cta">
                <Container maxWidth="lg" className="footer-cta-container">
                    <Typography variant="h2" className="footer-cta-title">
                        See The Possibilities of <br />2080 Clairmount Street, Detroit, MI
                    </Typography>
                    <Link to="/contact" style={{ textDecoration: 'none' }}> {/* Link to the contact page */}
                        <Button
                            variant="contained"
                            className="footer-cta-button"
                            endIcon={<ArrowForwardIcon />}
                        >
                            Get Started
                        </Button>
                    </Link>
                </Container>
            </Box>

            {/* Footer Content */}
            <Box className="footer-content">
                <Container maxWidth="lg">
                    <Grid container className="footer-grid">
                        {/* Logo and Description */}
                        <Grid item xs={12} md={4} className="footer-column footer-column-logo">
                            <div className="footer-section-container"> {/* New container for padding */}
                                <img src={img1} alt="Logo" className="footer-logo" />
                            </div>
                        </Grid>

                        {/* Quick Links */}
                        <Grid item xs={12} md={4} className="footer-column footer-column-links">
                            <div className="footer-section-container"> {/* New container for padding */}
                                <Typography variant="h6" className="footer-title">Quick Links</Typography>
                                <ul className="footer-links">
                                    <li><a href="/">Home</a></li>
                                    <li><Link to="/gallery">Gallery</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                    <li><Link to="/partnerships">Investment Partnerships</Link></li>
                                </ul>
                            </div>
                        </Grid>

                        {/* Contact Info (Rachel and Tracey) */}
                        <Grid item xs={12} md={3} className="footer-column footer-column-contact">
                            <div className="footer-section-container"> {/* New container for padding */}
                                <Typography variant="h6" className="footer-title">Contact Info</Typography>
                                <ul className="footer-contact-info">
                                    <li><strong>Rachel Blue</strong></li>
                                    <li>Rachel@EliteRealtyMi.com</li>
                                    <li>(313)457-7983</li>
                                    <li><strong>Tracey Petersen</strong></li>
                                    <li>treyscouter68@aol.com</li>
                                    <li>(248)470-0216</li>
                                </ul>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* Footer Bottom */}
            <Box className="footer-bottom">
                <Container maxWidth="lg">
                    <Typography variant="body2" className="footer-bottom-text">
                        Copyright © 2024 Elite Realty. All rights reserved.
                    </Typography>
                </Container>
            </Box>
        </footer>
    );
};

export default Footer;
